import React from 'react';
import ReactPlayer from 'react-player';
import PaySpace from './PaySpace.svg';
import Cook from './Cook.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={PaySpace} className="App-logo" alt="logo" />
        <img style={{width: "100%", height: "100%", filter: "blur(10px)"}} src={Cook} alt="cook" />
        <ReactPlayer 
          url='https://thepayspace.com/01.mp3' 
          playing={false}
          controls={true}
          volume={0.5}
          />
      </header>
    </div>
  );
}

export default App;
